/* You can add global styles to this file, and also import other style files */
@import "styles/variables";

@import "@fd/core/styles";

@import "styles/angular-material/overwrites";

mat-label button {
  pointer-events: all;
}
input[disabled],
input[readonly] {
  cursor: not-allowed;
}
